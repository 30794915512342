import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpInterceptorFn
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor (private authService: AuthenticationService) {}

  intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isAuthenticate()) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${this.authService.getToken()}` } })
    }
    return next.handle(request)
  }
}