import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import { IconDirective } from '@coreui/icons-angular';
import {
  ContainerComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular';

import { DefaultFooterComponent, DefaultHeaderComponent } from './';
import { buyerNavItems } from './_nav';
import { sellerNavItems } from './_nav';
import { buyerAdminNavItems } from './_nav';
import { sellerAdminNavItems } from './_nav';
import { superAdminNavItems } from './_nav';

import { navItems } from './_nav';
import { AuthenticationService } from 'src/app/services/authentication.service';


function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent
  ]
})
export class DefaultLayoutComponent {
  public navItems : any = [];

  constructor(private auth:AuthenticationService) { }

  ngOnInit(): void {

    let user = this.auth.user();
    console.log(user);

    switch(user.user_type) { 
      case "Buyer": { 
        this.navItems = buyerNavItems;

        if(user.is_company_admin)
        {
          this.navItems = buyerAdminNavItems;
        }
        break; 
      } 
      case "Seller": { 
        this.navItems = sellerNavItems;

        if(user.is_company_admin)
        {
          this.navItems = sellerAdminNavItems;
        }
        break; 
      } 
      default: { 
        this.navItems = navItems;
        break; 
      } 
    }

    if(user.is_super_admin)
    {
      this.navItems = superAdminNavItems;
    }
  }


  onScrollbarUpdate($event: any) {
  }
}
