import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { AuthGuard } from './services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes)
      },
      {
        path: 'theme',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/theme/routes').then((m) => m.routes)
      },
      {
        path: 'base',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/base/routes').then((m) => m.routes)
      },
      {
        path: 'buttons',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/buttons/routes').then((m) => m.routes)
      },
      {
        path: 'forms',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/forms/routes').then((m) => m.routes)
      },
      {
        path: 'icons',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/icons/routes').then((m) => m.routes)
      },
      {
        path: 'notifications',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/notifications/routes').then((m) => m.routes)
      },
      {
        path: 'widgets',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/widgets/routes').then((m) => m.routes)
      },
      {
        path: 'charts',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/charts/routes').then((m) => m.routes)
      },
      {
        path: 'pages',
        canActivate:[AuthGuard],
        loadChildren: () => import('./views/pages/routes').then((m) => m.routes)
      },
      {
        path: 'mat-table',
        canActivate:[AuthGuard],
        loadComponent: () => import('./table-grid/table-grid.component').then(m => m.TableGridComponent),
        data: {
          title: 'Table Grid'
        }
      },
      {
        path: 'eproject-clients',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/eproject-clients/eproject-clients/eproject-clients.component').then(m => m.EprojectClientsComponent),
        data: {
          title: 'Eproject Clients'
        }
      },
      {
        path: 'create-eproject-clients',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/eproject-clients/create-eproject-clients/create-eproject-clients.component').then(m => m.CreateEprojectClientsComponent),
        pathMatch : 'full',
        data: {
          title: 'Create Eproject Client'
        }
      },
      {
        path: 'edit-eproject-clients/:clientId',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/eproject-clients/edit-eproject-clients/edit-eproject-clients.component').then(m => m.EditEprojectClientsComponent),
        pathMatch : 'full',
        data: {
          title: 'Edit Eproject Client'
        }
      },
      {
        path: 'eproject-client-actions/:clientId',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/eproject-clients/eproject-client-actions/eproject-client-actions.component').then(m => m.EprojectClientActionsComponent),
        pathMatch : 'full',
        data: {
          title: 'Create Eproject Client'
        }
      },
      {
        path: 'company-users',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/company-users/company-users/company-users.component').then(m => m.CompanyUsersComponent),
        data: {
          title: 'Company Users'
        }
      },
      {
        path: 'create-users/:companyId',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/company-users/create-users/create-users.component').then(m => m.CreateUsersComponent),
        pathMatch : 'full',
        data: {
          title: 'Create User'
        }
      },
      {
        path: 'edit-user/:userId',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/company-users/edit-users/edit-users.component').then(m => m.EditUsersComponent),
        pathMatch : 'full',
        data: {
          title: 'Edit User'
        }
      },
      {
        path: 'profile',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/user-profile/user-profile.component').then(m => m.UserProfileComponent),
        data: {
          title: 'User Profile'
        }
      },
      {
        path: 'unit-of-measurements',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/unit-of-measurements/unit-of-measurements.component').then(m => m.UnitOfMeasurementsComponent),
        data: {
          title: 'Unit Of Measurements'
        }
      },
      {
        path: 'countries',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/countries/countries.component').then(m => m.CountriesComponent),
        data: {
          title: 'Countries'
        }
      },
      {
        path: 'currencies',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/currencies/currencies.component').then(m => m.CurrenciesComponent),
        data: {
          title: 'Currencies'
        }
      },
      {
        path: 'tax-codes',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/tax-codes/tax-codes.component').then(m => m.TaxCodesComponent),
        data: {
          title: 'Tax Codes'
        }
      },
      {
        path: 'business-entity-types',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/business-entity-types/business-entity-types.component').then(m => m.BusinessEntityTypesComponent),
        data: {
          title: 'Business Entity Types'
        }
      },
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./views/pages/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'eproject-login/:email/:token',
    loadComponent: () => import('./eproject-login/eproject-login.component').then(m => m.EprojectLoginComponent),
    data: {
      title: 'EProject Login'
    }
  },
  {
    path: 'confirm-user/:email',
    loadComponent: () => import('./confirm-user/confirm-user.component').then(m => m.ConfirmUserComponent),
    data: {
      title: 'Confirm User'
    }
  },
  {
    path: 'register',
    loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  { path: '**', redirectTo: 'dashboard' }
];
